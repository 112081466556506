<template>
  <div class="approval-action-form">
    <form
      class="ui form"
      @submit.prevent="handleSubmit"
    >
      <div class="field">
        <div class="ui fluid buttons">
          <button
            type="button"
            class="ui button"
            :class="{green: selectedAction === 'approve', basic: selectedAction !== 'approve'}"
            :disabled="
              isSubmitting ||
              isDisabled ||
              (availableActions.length && !availableActions.includes('approve'))
            "
            @click="updateSelectedAction('approve')"
          >
            Approve
          </button>
          <button
            type="button"
            class="ui button"
            :class="{negative: selectedAction === 'reject', basic: selectedAction !== 'reject'}"
            :disabled="
              isSubmitting ||
              isDisabled ||
              (availableActions.length && !availableActions.includes('reject'))
            "
            @click="updateSelectedAction('reject')"
          >
            Reject
          </button>
        </div>
      </div>
      <div class="field">
        <label>Comment (optional)</label>
        <textarea
          :value="comment"
          rows="3"
          :disabled="isSubmitting || isDisabled"
          @input="updateComment"
        ></textarea>
      </div>
      <button
        class="ui primary button"
        type="submit"
        :disabled="!selectedAction || isSubmitting || isDisabled"
        :class="{loading: isSubmitting}"
      >
        Submit
      </button>
    </form>
  </div>
</template>

<script setup>
import {computed} from 'vue'

const props = defineProps({
  approvalData: {
    type: Object,
    required: true
  },
  selectedAction: {
    type: String,
    default: ''
  },
  comment: {
    type: String,
    default: ''
  },
  isSubmitting: {
    type: Boolean,
    default: false
  },
  isSubmitted: {
    type: Boolean,
    default: false
  },
  availableActions: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(['update-selected-action', 'update-comment', 'submit'])

// Computed property to determine if form should be disabled
const isDisabled = computed(() => {
  if (!props.approvalData) return true

  // Check if token is expired or already used
  const isExpired = new Date(props.approvalData.expires_at) < new Date()
  const isAlreadyUsed =
    props.approvalData.status === 'completed' ||
    props.approvalData.status === 'approved' ||
    props.approvalData.status === 'rejected'

  return isExpired || isAlreadyUsed || props.isSubmitted
})

// Handle action selection
const updateSelectedAction = action => {
  emit('update-selected-action', action)
}

// Handle comment update
const updateComment = event => {
  emit('update-comment', event.target.value)
}

// Handle form submission
const handleSubmit = () => {
  emit('submit')
}
</script>

<style lang="scss" scoped>
.approval-action-form {
  .ui.form {
    .field {
      margin-bottom: 1rem;
    }

    .ui.buttons {
      margin-bottom: 0.5rem;
    }

    textarea {
      resize: vertical;
    }

    .ui.button {
      margin-top: 0.5rem;
    }
  }
}
</style>
