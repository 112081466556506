<template>
  <div class="additional-information">
    <!-- Approval Information Section -->
    <h5 class="ui dividing header">Approval Information</h5>
    <table :class="tableClasses">
      <tbody>
        <tr v-if="approvalData.id">
          <td>Approval ID</td>
          <td>{{ approvalData.id }}</td>
        </tr>
        <tr v-if="approvalData.email">
          <td>Email</td>
          <td>{{ approvalData.email }}</td>
        </tr>
        <tr v-if="approvalData.requested_by">
          <td>Requested By</td>
          <td>{{ approvalData.requested_by }}</td>
        </tr>
        <tr v-if="approvalData.requested_at">
          <td>Requested At</td>
          <td>{{ formatDate(approvalData.requested_at) }}</td>
        </tr>
        <tr v-if="approvalData.created_at">
          <td>Created At</td>
          <td>{{ formatDate(approvalData.created_at) }}</td>
        </tr>
        <tr v-if="approvalData.expires_at">
          <td>Expires At</td>
          <td>{{ formatDate(approvalData.expires_at) }}</td>
        </tr>
        <tr v-if="approvalData.used !== undefined">
          <td>Used</td>
          <td>{{ approvalData.used ? 'Yes' : 'No' }}</td>
        </tr>
        <tr v-if="approvalData.is_valid !== undefined">
          <td>Valid</td>
          <td>{{ approvalData.is_valid ? 'Yes' : 'No' }}</td>
        </tr>
        <tr v-if="approvalData.is_expired !== undefined">
          <td>Expired</td>
          <td>{{ approvalData.is_expired ? 'Yes' : 'No' }}</td>
        </tr>
      </tbody>
    </table>

    <!-- Order Information Section -->
    <h5
      v-if="approvalData.order"
      class="ui dividing header"
    >
      Order Information
    </h5>
    <table
      v-if="approvalData.order"
      :class="tableClasses"
    >
      <tbody>
        <tr v-if="approvalData.order.id">
          <td>Order ID</td>
          <td>{{ approvalData.order.id }}</td>
        </tr>
        <tr v-if="approvalData.order.name">
          <td>Order Name</td>
          <td>{{ approvalData.order.name }}</td>
        </tr>
        <tr v-if="approvalData.order.description">
          <td>Description</td>
          <td>{{ approvalData.order.description }}</td>
        </tr>
        <tr v-if="approvalData.order.customer_order_reference">
          <td>Customer Reference</td>
          <td>{{ approvalData.order.customer_order_reference }}</td>
        </tr>
        <tr v-if="approvalData.order.custom_article_number">
          <td>Article Number</td>
          <td>{{ approvalData.order.custom_article_number }}</td>
        </tr>
        <tr v-if="approvalData.order.start_date">
          <td>Start Date</td>
          <td>{{ formatDate(approvalData.order.start_date) }}</td>
        </tr>
      </tbody>
    </table>

    <!-- Technical Details Section -->
    <h5
      v-if="hasTechnicalDetails"
      class="ui dividing header"
    >
      Technical Details
    </h5>
    <table
      v-if="hasTechnicalDetails"
      :class="tableClasses"
    >
      <tbody>
        <tr
          v-if="
            approvalData.order?.flat_artwork_size_width &&
            approvalData.order?.flat_artwork_size_height
          "
        >
          <td>Artwork Size</td>
          <td
            >{{ approvalData.order.flat_artwork_size_width }} ×
            {{ approvalData.order.flat_artwork_size_height }}</td
          >
        </tr>
        <tr v-if="approvalData.order?.maximum_amount_of_colors_briefed">
          <td>Max Colors</td>
          <td>{{ approvalData.order.maximum_amount_of_colors_briefed }}</td>
        </tr>
        <tr v-if="approvalData.order?.step_and_repeat_required !== undefined">
          <td>Step and Repeat Required</td>
          <td>{{ approvalData.order.step_and_repeat_required ? 'Yes' : 'No' }}</td>
        </tr>
      </tbody>
    </table>

    <!-- Additional Notes Section -->
    <h5
      v-if="hasNotes"
      class="ui dividing header"
    >
      Notes
    </h5>
    <table
      v-if="hasNotes"
      :class="tableClasses"
    >
      <tbody>
        <tr v-if="approvalData.notes">
          <td>Approval Notes</td>
          <td>{{ approvalData.notes }}</td>
        </tr>
        <tr v-if="approvalData.order?.customer_briefing">
          <td>Customer Briefing</td>
          <td>{{ approvalData.order.customer_briefing }}</td>
        </tr>
        <tr v-if="approvalData.order?.technical_drawing">
          <td>Technical Drawing</td>
          <td>{{ approvalData.order.technical_drawing }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import {computed} from 'vue'

const props = defineProps({
  approvalData: {
    type: Object,
    required: true
  }
})

const tableClasses = 'ui mini basic definition fitted compact table'

// Check if technical details are available
const hasTechnicalDetails = computed(() => {
  return Boolean(
    props.approvalData.order?.flat_artwork_size_width ||
      props.approvalData.order?.maximum_amount_of_colors_briefed ||
      props.approvalData.order?.step_and_repeat_required !== undefined
  )
})

// Check if notes are available
const hasNotes = computed(() => {
  return Boolean(
    props.approvalData.notes ||
      props.approvalData.order?.customer_briefing ||
      props.approvalData.order?.technical_drawing
  )
})

// Format date for display
const formatDate = dateString => {
  if (!dateString) return 'N/A'

  // Use Intl.DateTimeFormat for better performance than new Date().toLocaleString()
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }

  return new Intl.DateTimeFormat(undefined, options).format(new Date(dateString))
}
</script>

<style lang="scss" scoped>
.additional-information {
  .section-title {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    font-weight: bold;

    &:first-child {
      margin-top: 0;
    }
  }

  .ui.table {
    margin: 0.5rem 0;

    td:first-child {
      font-weight: bold;
      width: 35%;
    }
  }
}
</style>
