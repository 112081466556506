<template>
  <div class="approval-details">
    <div class="ui list">
      <div class="item">
        <div class="header">Approval ID</div>
        <div>{{ approvalData.id }}</div>
      </div>
      <div class="item">
        <div class="header">Order ID</div>
        <div>{{ approvalData.order_id }}</div>
      </div>
      <div class="item">
        <div class="header">Email</div>
        <div>{{ approvalData.email }}</div>
      </div>
      <div class="item">
        <div class="header">Expires</div>
        <div>{{ formatDate(approvalData.expires_at) }}</div>
      </div>
      <div class="item">
        <div class="header">Status</div>
        <div>{{ approvalData.status }}</div>
      </div>
      <!-- Order Details -->
      <div
        v-if="approvalData.order_number"
        class="item"
      >
        <div class="header">Order Number</div>
        <div>{{ approvalData.order_number }}</div>
      </div>
      <div
        v-if="approvalData.order_name"
        class="item"
      >
        <div class="header">Order Name</div>
        <div>{{ approvalData.order_name }}</div>
      </div>
      <div
        v-if="approvalData.customer_name"
        class="item"
      >
        <div class="header">Customer</div>
        <div>{{ approvalData.customer_name }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps} from 'vue'

defineProps({
  approvalData: {
    type: Object,
    required: true
  }
})

// Format date for display
const formatDate = dateString => {
  if (!dateString) return 'N/A'
  return new Date(dateString).toLocaleString()
}
</script>

<style lang="scss" scoped>
.approval-details {
  .ui.list {
    .item {
      margin-bottom: 0.5rem;

      .header {
        font-weight: bold;
        margin-bottom: 0.25rem;
      }
    }
  }
}
</style>
