<template>
  <div class="sidebar">
    <div class="action-buttons">
      <div class="ui buttons fluid">
        <button
          class="ui button"
          :class="{active: currentViewType === 'single'}"
          :disabled="disabled"
          @click="handleFetchSingle"
        >
          Single file
        </button>

        <button
          class="ui button"
          :class="{active: currentViewType === 'stepRepeat'}"
          :disabled="disabled"
          @click="handleFetchStepRepeat"
        >
          S&amp;R file
        </button>
      </div>
    </div>

    <div
      ref="scrollableRef"
      class="scrollable"
    >
      <div class="ui fluid styled accordion">
        <template
          v-for="(item, index) in accordionItems"
          :key="`accordion-item-${index}`"
        >
          <div
            :class="['title', {active: activeIndex === index}]"
            @click="toggleAccordion(index)"
          >
            <i class="dropdown icon"></i>
            {{ item.title }}
          </div>
          <div :class="['content', {active: activeIndex === index}]">
            <component
              :is="getComponentForTemplate(item)"
              v-if="shouldRenderComponent(item)"
              v-bind="getComponentProps(item)"
              @update-selected-action="updateSelectedAction"
              @update-comment="updateComment"
              @submit="handleSubmit"
            />

            <!-- Default content -->
            <div v-else-if="item.content">
              <p
                v-for="n in 30"
                :key="`content-${index}-${n}`"
              >
                {{ item.content }} {{ n }}
              </p>
            </div>
          </div>
        </template>

        <!-- Success Message -->
        <div
          v-if="isSubmitted"
          class="ui success message"
        >
          <div class="header">Action Submitted Successfully</div>
          <p>Your decision has been recorded. Thank you!</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, nextTick, watch, computed, shallowRef} from 'vue'
import ApprovalDetails from './ApprovalDetails.vue'
import AdditionalInformation from './AdditionalInformation.vue'
import ApprovalActionForm from './ApprovalActionForm.vue'

// Props definition
const props = defineProps({
  disabled: Boolean,
  currentViewType: {
    type: String,
    required: true,
    validator: value => ['single', 'stepRepeat'].includes(value)
  },
  approvalData: Object,
  isSubmitting: Boolean,
  isSubmitted: Boolean,
  selectedAction: {
    type: String,
    default: ''
  },
  comment: {
    type: String,
    default: ''
  },
  availableActions: {
    type: Array,
    default: () => []
  }
})

// Emits definition
const emit = defineEmits([
  'fetchSingle',
  'fetchStepRepeat',
  'updateSelectedAction',
  'updateComment',
  'submitAction'
])

// Refs
const activeIndex = ref(0)
const scrollableRef = ref(null)

// Component map for dynamic component rendering
const componentMap = shallowRef({
  'approval-details': ApprovalDetails,
  'additional-info': AdditionalInformation,
  'action-form': ApprovalActionForm
})

// Dynamic accordion items based on approval data
const accordionItems = computed(() => {
  if (!props.approvalData) {
    return [
      {title: 'Section 1', content: 'Content for section 1.'},
      {title: 'Section 2', content: 'Content for section 2.'},
      {title: 'Section 3', content: 'Content for section 3.'}
    ]
  }

  const items = [
    {title: 'Details', template: 'approval-details'},
    {title: 'Additional Information', template: 'additional-info'}
  ]

  if (!props.isSubmitted) {
    items.push({title: 'Actions', template: 'action-form'})
  }

  return items
})

// Helper functions for the dynamic component
const getComponentForTemplate = item => {
  return item.template ? componentMap.value[item.template] : null
}

const shouldRenderComponent = item => {
  return item.template && props.approvalData
}

const getComponentProps = item => {
  if (item.template === 'action-form') {
    return {
      approvalData: props.approvalData,
      selectedAction: props.selectedAction,
      comment: props.comment,
      isSubmitting: props.isSubmitting,
      isSubmitted: props.isSubmitted,
      availableActions: props.availableActions
    }
  }

  return {approvalData: props.approvalData}
}

// Event handlers
const handleFetchSingle = () => emit('fetchSingle')
const handleFetchStepRepeat = () => emit('fetchStepRepeat')
const updateSelectedAction = action => emit('updateSelectedAction', action)
const updateComment = event => emit('updateComment', event.target.value)
const handleSubmit = () => emit('submitAction')

// Accordion management
const toggleAccordion = index => {
  activeIndex.value = activeIndex.value === index ? null : index
  if (activeIndex.value !== null) {
    nextTick(scrollToBottom)
  }
}

// Scroll management - optimized
const scrollToBottom = () => {
  if (!scrollableRef.value) return

  requestAnimationFrame(() => {
    scrollableRef.value.scrollTop = scrollableRef.value.scrollHeight
  })
}

// Optimized ensureScrollable function
const ensureScrollable = () => {
  if (!scrollableRef.value) return

  // Using requestAnimationFrame for better performance
  requestAnimationFrame(() => {
    // Force layout recalculation using a more efficient approach
    const height = scrollableRef.value.scrollHeight
    scrollableRef.value.style.display = 'none'

    scrollableRef.value.offsetHeight // Force reflow
    scrollableRef.value.style.display = ''

    // If content became smaller, we still want to scroll to bottom
    if (scrollableRef.value.scrollHeight !== height) {
      scrollToBottom()
    }
  })
}

// Use flush: 'post' to ensure all DOM updates are complete before scrolling
watch(
  () => activeIndex.value,
  () => {
    ensureScrollable()
  },
  {flush: 'post'}
)

// Lifecycle hooks
onMounted(() => {
  nextTick(() => {
    ensureScrollable()
    scrollToBottom()
  })
})
</script>

<style lang="scss" scoped>
.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;

  .action-buttons {
    flex: 0 0 auto;
    padding: 1rem;
    background-color: white;
    z-index: 2;
    position: sticky;
    top: 0;
  }

  .scrollable {
    flex: 1 1 auto;
    overflow-y: auto !important;
    max-height: calc(100vh - 70px); /* Adjust based on action-buttons height */
    position: relative;
    padding-bottom: 1rem;
    overscroll-behavior: contain; /* Prevents parent scrolling when reaching boundaries */

    .ui.accordion {
      margin-bottom: 70px;
      width: 100%;
      will-change: transform; /* Hardware acceleration hint */

      .title {
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.2s ease;

        &:hover {
          background-color: rgba(0, 0, 0, 0.03);
        }

        &.active {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }

      .content.active {
        display: block !important;
        overflow: visible;
        height: auto !important;

        .ui.list {
          .item {
            margin-bottom: 0.5rem;

            .header {
              font-weight: bold;
              margin-bottom: 0.25rem;
            }
          }
        }
      }
    }

    .ui.success.message {
      margin-top: 1rem;
    }
  }
}
</style>
