import { readonly } from 'vue'
import * as Sentry from '@sentry/vue'
import debounce from 'lodash.debounce'
import hotkeys from 'hotkeys-js'
import { ray } from 'node-ray/web'
import { getSystemStore, getUserStore } from '@/stores/storeBootstrap'
import { appSettings, backendSettings } from '@/config'
import filtersPlugin from './plugins/filtersPlugin'
import jQueryPlugin from './plugins/jQueryPlugin'
import '@asika32764/vue-animate/dist/vue-animate.css'
import {
  createAppRouter,
  createAuthenticatedRoutes
} from '@/router/router.js'

import mitt from 'mitt'
import VueConfetti from 'vue-confetti'
import EchoPlugin from '@/plugins/EchoPlugin'
import cachePlugin from '@/plugins/cachePlugin'
import { handlePostAuthRedirect } from '@/bootstrap/redirectHandler'

// Initialize jQuery globally first, before anything else
const $ = jQueryPlugin.install()
// Add other global objects
Object.assign(window, { ray, hotkeys, debounce })

async function bootAuthenticatedApplication(app) {
  // eslint-disable-next-line no-console
  console.group('Booting Authenticated Application')

  const [systemStore, userStore] = await Promise.all([
    getSystemStore(),
    getUserStore()
  ])

  // Ensure system data is loaded. This is critical for authenticated routes.
  let systemDataLoaded = !systemStore.isSystemDataEmpty();
  if (!systemDataLoaded) {
    // eslint-disable-next-line no-console
    console.log('[Authenticated] System data not loaded or expired, fetching now');
    try {
      await systemStore.fetchSystemData();
      systemDataLoaded = !systemStore.isSystemDataEmpty(); // Re-check after fetch
      if (systemDataLoaded) {
        // eslint-disable-next-line no-console
        console.log('[Authenticated] System data loaded successfully');
      } else {
         // This case should ideally not happen if fetchSystemData resolves without error and populates data
         // eslint-disable-next-line no-console
         console.error('[Authenticated] System data fetch seemed successful but store is still empty.');
         // Throw an error to prevent proceeding without data
         throw new Error("System data fetch completed but store remains empty.");
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[Authenticated] CRITICAL: Failed to load system data. Cannot initialize authenticated application state.', error);
      // Throw or handle critical failure - prevent app from continuing in a broken state
      // For now, re-throwing to stop the boot process here.
      // Consider redirecting to an error page or showing a global message.
      throw new Error(`Failed to load essential system data: ${error.message}`);
    }
  } else {
    // eslint-disable-next-line no-console
    console.log('[Authenticated] System data already loaded from cache');
  }

  const getUserPreferences = () => ({
    locale: userStore.user?.language?.iso639_1_code,
    format: userStore.user?.date_format?.format
  })

  // Setup global properties
  // eslint-disable-next-line no-console
  console.group('Setting Up Global Properties')
  const globalProperties = app.config.globalProperties
  globalProperties.appSettings = readonly(appSettings)
  globalProperties.backendSettings = readonly(backendSettings)
  globalProperties.isDevelopmentMode = appSettings.mode === 'development'
  // Make jQuery available through the application
  globalProperties.$ = $
  globalProperties.jQuery = $

  // Parallel module loading for better performance
  const [
    toastModule,
    notificationModule,
    dateModule,
    queryStringParserModule
  ] = await Promise.all([
    import('@/bootstrap/toast'),
    import('@/bootstrap/notification'),
    import('@/bootstrap/date'),
    import('@/bootstrap/queryStringParser')
  ])

  globalProperties.$toast = toastModule.default
  globalProperties.$notification = notificationModule.default
  globalProperties.$date = new dateModule.default(getUserPreferences)
  globalProperties.$queryStringParser = queryStringParserModule.default
  globalProperties.$mitt = mitt()

  if (appSettings.mode === 'development') {
    const { faker } = await import('@faker-js/faker')
    globalProperties.$faker = faker
  }

  // eslint-disable-next-line no-console
  console.info('Global properties set', globalProperties)
  // eslint-disable-next-line no-console
  console.groupEnd('Setting Up Global Properties')

  if (userStore.isAuthenticated) {
    // eslint-disable-next-line no-console
    console.group('Configuring Authenticated User')
    
    // Define router at a higher scope so it's accessible outside the try block
    let router;
    
    // eslint-disable-next-line no-console
    console.log('[Authenticated] Creating authenticated routes');
    
    try {
      // We've already ensured systemDataLoaded is true if we reach here.
      // Pass the confirmed loaded systemStore instance
      const routes = await createAuthenticatedRoutes(systemStore);
      // eslint-disable-next-line no-console
      console.log(`[Authenticated] ${routes?.length || 0} routes created, creating router instance`);

      router = createAppRouter(routes);
      app.use(router);

      // Make router available globally for debugging
      app.config.globalProperties.$router = router;

      // eslint-disable-next-line no-console
      console.log('[Authenticated] Setting up ACL');
      const { setupAcl } = await import('@/bootstrap/acl.js');
      const acl = await setupAcl(router);
      app.use(acl);
      // eslint-disable-next-line no-console
      console.log('[Authenticated] Router and ACL setup complete');
      
      // Check if we have a redirect path in localStorage
      const hasRedirectPath = localStorage.getItem('auth_redirect_path') !== null;
      
                console.log(systemStore.indexableResourceModels)
      
      if (hasRedirectPath && router) {
        // eslint-disable-next-line no-console
        console.log('[Authenticated] Found redirect path in localStorage');
        
        // Ensure system data is fully loaded before handling redirect
        // This is crucial for proper route generation and navigation
        if (!systemStore.isSystemDataEmpty()) {
          // Handle redirect after routes and ACL are fully registered
          // eslint-disable-next-line no-console
          console.log('[Authenticated] System data loaded, handling post-auth redirect');
          
          // Use a small delay to ensure everything is fully initialized
          setTimeout(() => {
            handlePostAuthRedirect(router);
          }, 100);

        } else {
          // If system data is not yet loaded, wait for it to complete
          // eslint-disable-next-line no-console
          console.log('[Authenticated] Waiting for system data to load before redirect');
          
          // Set up a watcher to handle redirect once system data is loaded
          const checkSystemDataInterval = setInterval(() => {
            if (!systemStore.isSystemDataEmpty()) {
              // eslint-disable-next-line no-console
              console.log('[Authenticated] System data now loaded, handling redirect');
              clearInterval(checkSystemDataInterval);
              handlePostAuthRedirect(router);
            }
          }, 100);
          
          // Safety timeout to prevent infinite waiting
          setTimeout(() => {
            clearInterval(checkSystemDataInterval);
            // Try redirect anyway after timeout
            // eslint-disable-next-line no-console
            console.log('[Authenticated] Timeout reached, attempting redirect anyway');
            handlePostAuthRedirect(router);
          }, 5000);
        }
      } else {
        // eslint-disable-next-line no-console
        console.log('[Authenticated] No redirect path found, skipping redirect');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[Authenticated] Error setting up router:', error);
    }

    // eslint-disable-next-line no-console
    console.log('App configured for authenticated user')
    // eslint-disable-next-line no-console
    console.groupEnd('Configuring Authenticated User')
  }

  // eslint-disable-next-line no-console
  console.group('Setting Up Additional Features')

  // Setup precognition directive
  const debouncedEmit = debounce((input) => {
    globalProperties.$mitt.emit('precognition', input)
  }, 50)

  app.directive('precognite', {
    mounted: el => el.addEventListener('keyup', debouncedEmit),
    beforeUnmount: el => el.removeEventListener('keyup', debouncedEmit)
  })

  // Import core Fomantic UI components in parallel
  await Promise.all([
    import('@/vendor/fomantic/dist/components/form.min.js'),
    import('@/vendor/fomantic/dist/components/transition.min.js'),
    // import('@/vendor/fomantic/dist/components/dropdown.min.js'),
    import('@/vendor/fomantic/dist/components/checkbox.min.js'),
    // import('@/vendor/fomantic/dist/components/accordion.min.js'),
    import('@/vendor/fomantic/dist/components/modal.min.js'),
    import('@/vendor/fomantic/dist/components/popup.min.js'),
    // import('@/vendor/fomantic/dist/components/search.min.js'),
    import('@/vendor/fomantic/dist/components/toast.min.js')
  ])

  // Consolidated component imports with chunked parallel loading
  const componentImports = {
    basic: {
      DateLabel: () => import('@/components/Modules/Labels/DateLabel.vue'),
      CountLabel: () => import('@/components/Modules/Labels/CountLabel.vue'),
      Message: () => import('@/components/Modules/Message/Default.vue'),
      RelationLabel: () => import('@/components/Modules/Labels/RelationLabel.vue'),
      BooleanLabel: () => import('@/components/Modules/Labels/BooleanLabel.vue'),
      CopyButton: () => import('@/components/Modules/Labels/CopyButton.vue'),
      InfoLabel: () => import('@/components/Modules/Labels/InfoLabel.vue'),
      InfoPopup: () => import('@/components/Modules/Labels/InfoPopup.vue')
    },
    form: {
      DatePicker: () => import('@/components/Modules/DatePicker/Index.vue'),
      DropDown: () => import('@/components/Modules/Fomantic/DropDown.vue'),
      RadioBoxes: () => import('@/components/Modules/Fomantic/RadioBoxes.vue'),
      CheckBoxes: () => import('@/components/Modules/Fomantic/CheckBoxes.vue'),
      TextField: () => import('@/components/Modules/Fomantic/TextField.vue'),
      PriceField: () => import('@/components/Modules/Fomantic/PriceField.vue')
    },
    complex: {
      AutoTable: () => import('@/components/Modules/Fomantic/AutomaticTable.vue'),
      Pagination: () => import('@/components/Modules/Pagination/Default.vue'),
      Debug: () => import('@/components/Modules/Debug/Debugger.vue'),
      CrudButtons: () => import('@/components/CRUD/Buttons.vue'),
      Breadcrumb: () => import('@/components/CRUD/Breadcrumb.vue'),
      FlexPanels: () => import('@/components/Modules/Fomantic/FlexPanelsModule.vue'),
      ProgressLabel: () => import('@/components/Modules/Labels/ProgressLabel.vue'),
      DynamicDataOverview: () => import('@/components/Modules/Labels/DynamicDataOverview.vue'),
      InkLabel: () => import('@/views/Models/ProofingProfiles/Index/SearchLabels/InkLabel.vue')
    }
  };

  // Load component groups in parallel for better performance
  const [basicComponents, formComponents, complexComponents] = await Promise.all([
    Promise.all(
      Object.entries(componentImports.basic).map(([name, importFn]) =>
        importFn().then(module => ({ name, component: module.default || module }))
      )
    ),
    Promise.all(
      Object.entries(componentImports.form).map(([name, importFn]) =>
        importFn().then(module => ({ name, component: module.default || module }))
      )
    ),
    Promise.all(
      Object.entries(componentImports.complex).map(([name, importFn]) =>
        importFn().then(module => ({ name, component: module.default || module }))
      )
    )
  ]);

  // Register all components
  [...basicComponents, ...formComponents, ...complexComponents].forEach(({ name, component }) => {
    app.component(name, component);
  });

  // Setup Sentry in production
  if (import.meta.env.MODE !== 'development') {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.VITE_APP_ENVIRONMENT,
      release: import.meta.env.VITE_SENTRY_RELEASE,
      integrations: [
        // Use updated integration
        Sentry.browserTracingIntegration()
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: ['localhost', /^https:\/\/artworkflow-backend.volkers\.nl\/api/],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0
    })

    Sentry.setUser({
      id: userStore.user.id,
      email: userStore.user.email,
      name: userStore.user.name
    })

    const { default: loggerPlugin } = await import('@/plugins/loggerPlugin')
    app.use(loggerPlugin, {
      sendErrorsImmediately: true,
      flushInterval: 60000
    })
  }

  // Use Pusher settings from the system store, with fallback to env variables
  const pusherAppKey = systemStore.pusherSettings?.appKey || import.meta.env.VITE_PUSHER_APP_KEY
  const pusherCluster = systemStore.pusherSettings?.cluster || import.meta.env.VITE_PUSHER_CLUSTER

  app
    .use(VueConfetti)
    .use(EchoPlugin, {
      token: userStore.token,
      referrer: backendSettings.baseUrl,
      authEndpoint: `${backendSettings.baseUrl}/api/broadcasting/auth`,
      broadcaster: 'pusher',
      key: pusherAppKey,
      cluster: pusherCluster
    })
    .use(cachePlugin, { storage: 'localStorage' })
    .use(filtersPlugin)

  // eslint-disable-next-line no-console
  console.log('Additional features set up', pusherAppKey, pusherCluster)

  // eslint-disable-next-line no-console
  console.log('App mounted for authenticated user', app)
  // eslint-disable-next-line no-console
  console.groupEnd('Setting Up Additional Features')
  // eslint-disable-next-line no-console
  console.groupEnd('Booting Authenticated Application')
}

export { bootAuthenticatedApplication }
