<template>
  <div class="approval-page">
    <!-- Main loading state for the entire page -->
    <div
      v-if="pageLoading"
      class="page-loader-container"
    >
      <div class="ui active dimmer">
        <div class="ui large text loader">Loading approval page...</div>
      </div>
    </div>

    <!-- Only render the rest of the content when page is ready -->
    <template v-else>
      <!-- Show loading indicator when data is being fetched -->
      <div
        v-if="isLoading"
        class="loader-container"
      >
        <div class="ui active dimmer">
          <div class="ui text loader">Loading approval details...</div>
        </div>
      </div>

      <!-- Show centralized error message if there's an error -->
      <div
        v-else-if="error"
        class="error-container"
      >
        <div class="ui negative message">
          <div class="header">Error</div>
          <p>{{ error }}</p>
        </div>
      </div>

      <!-- Show normal split view layout when no errors -->
      <template v-else>
        <div class="sidebar-container">
          <sidebar
            :disabled="isLoading"
            :current-view-type="currentViewType"
            :approval-data="approvalData"
            :is-submitting="isSubmitting"
            :is-submitted="isSubmitted"
            :selected-action="selectedAction"
            :comment="comment"
            :available-actions="availableActions"
            @fetch-single="handleFetchSingle"
            @fetch-step-repeat="handleFetchStepRepeat"
            @update-selected-action="selectedAction = $event"
            @update-comment="comment = $event"
            @submit-action="submitAction"
          />
        </div>
        <div class="viewer-container">
          <viewer
            v-if="viewerUrl"
            :viewer-url="viewerUrl"
          />
          <div
            v-else
            class="ui placeholder segment"
          >
            <div class="ui icon header">
              <i class="file outline icon"></i>
              No document preview available
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted, watch, computed} from 'vue'
import {useDocumentVisibility} from '@vueuse/core'
import {useRoute, useRouter} from 'vue-router'
import Sidebar from './Partials/Sidebar.vue'
import Viewer from './Partials/Viewer.vue'
import backendApiClient from '@/services/backendApiClient'
import authenticatedApiClient from '@/services/authenticatedApiClient'
import {storeImports} from '@/stores/storeImports'

// --------------------
// State
// --------------------
const route = useRoute()
const router = useRouter()
const visibility = useDocumentVisibility()
const userStore = ref(null)

// Compute which API client to use based on authentication status
const apiClient = computed(() => {
  return userStore.value?.isAuthenticated ? authenticatedApiClient : backendApiClient
})

// Core data
const token = ref('')
const signature = ref('') // Keep for backward compatibility
const approvalData = ref(null)
const viewerUrl = ref('')
const currentViewType = ref('single') // Default view type is 'single'

// UI state
const pageLoading = ref(true) // Main page loading state
const isLoading = ref(true)
const error = ref(null)
const cssImportsLoaded = ref(false)

// Form state
const selectedAction = ref('')
const comment = ref('')
const isSubmitting = ref(false)
const isSubmitted = ref(false)
const isActionFormOpen = ref(true)
const availableActions = ref([])

// Polling
let pollingInterval = null

// --------------------
// URL Management
// --------------------
// Function to update URL query parameter without reloading page
const updateUrlQuery = type => {
  router.replace({query: {...route.query, view: type}})
}

// --------------------
// API Calls
// --------------------
// Fetch approval data using the token
const fetchApprovalData = async tokenValue => {
  isLoading.value = true
  error.value = null

  try {
    // eslint-disable-next-line no-console
    console.log(`Fetching approval data for token: ${tokenValue}`)

    const response = await apiClient.value.get(`/api/v1/approval-links/${tokenValue}`)
    approvalData.value = response.data.data

    // Set available actions if provided
    if (response.data.available_actions && Array.isArray(response.data.available_actions)) {
      availableActions.value = response.data.available_actions
    }

    // Set default viewer URL to single file URL if available
    if (approvalData.value?.single_file_url) {
      viewerUrl.value = approvalData.value.single_file_url
    } else if (approvalData.value?.viewer_url) {
      // Fallback to viewer_url for backward compatibility
      viewerUrl.value = approvalData.value.viewer_url
    } else {
      viewerUrl.value = ''
      error.value = 'No file URL available in the approval data.'
    }

    // Check if already submitted
    if (response.data.status === 'approved' || response.data.status === 'rejected') {
      isSubmitted.value = true
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Failed to fetch approval data:', err)

    if (err.response && err.response.status === 404) {
      error.value = 'This link has expired or was already used.'
    } else if (err.response && err.response.status === 401) {
      error.value = 'You are not authorized to access this link.'
    } else {
      error.value = 'Failed to load data. Please try again later.'
    }
  } finally {
    isLoading.value = false
    pageLoading.value = false // Finish the main page loading
  }
}

// Function to fetch updated approval data
const fetchApprovalUpdates = async () => {
  if (!token.value) return // Don't fetch if token is missing

  // eslint-disable-next-line no-console
  console.log(`Polling for updates for token: ${token.value}...`)

  try {
    const response = await apiClient.value.get(`/api/v1/approval-links/${token.value}`)

    // Update the approval data with the response
    approvalData.value = response.data

    // Update submission status if needed
    if (response.data.status === 'approved' || response.data.status === 'rejected') {
      isSubmitted.value = true
    }

    // Update available actions if provided
    if (response.data.available_actions && Array.isArray(response.data.available_actions)) {
      availableActions.value = response.data.available_actions
    }

    // Update viewer URL if provided
    // if (response.data.viewer_url) {
    //   viewerUrl.value = response.data.viewer_url
    // }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Polling failed:', err)

    // Update error state based on the error
    if (err.response) {
      if (err.response.status === 404) {
        error.value = 'This approval link has expired or was already used.'
        isSubmitted.value = true // Consider it submitted to stop polling
      } else if (err.response.status === 401) {
        error.value = 'You are not authorized to access this approval link.'
      } else {
        error.value = 'Failed to update approval data. Please try again later.'
      }
    } else {
      error.value = 'Network error. Please check your connection and try again.'
    }
  }
}

// Submit approval action
const submitAction = async () => {
  if (!token.value || !selectedAction.value) return

  isSubmitting.value = true

  try {
    // eslint-disable-next-line no-console
    console.log(`Submitting ${selectedAction.value} action for token: ${token.value}`)

    const payload = {
      action: selectedAction.value,
      comment: comment.value
    }

    await apiClient.value.post(`/api/v1/approval-links/${token.value}/submit`, payload)

    // Update local state
    isSubmitted.value = true
    approvalData.value = {
      ...approvalData.value,
      status: selectedAction.value === 'approve' ? 'approved' : 'rejected'
    }

    // Close the form accordion
    isActionFormOpen.value = false
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Failed to submit approval action:', err)

    if (err.response && err.response.data && err.response.data.message) {
      error.value = err.response.data.message
    } else {
      error.value = 'Failed to submit your decision. Please try again later.'
    }
  } finally {
    isSubmitting.value = false
  }
}

// --------------------
// View Type Handlers
// --------------------
// Handler for the 'Single' button click
const handleFetchSingle = () => {
  if (currentViewType.value === 'single') return
  currentViewType.value = 'single'
  updateUrlQuery('single')

  // Only use URLs from the API response
  if (approvalData.value?.single_file_url) {
    viewerUrl.value = approvalData.value.single_file_url
  } else if (approvalData.value?.viewer_url) {
    // Fallback to viewer_url for backward compatibility
    viewerUrl.value = approvalData.value.viewer_url
  } else {
    // No URL available
    viewerUrl.value = ''
    error.value = 'No single file URL available in the approval data.'
  }
}

// Handler for the 'Step and repeat' button click
const handleFetchStepRepeat = () => {
  if (currentViewType.value === 'stepRepeat') return
  currentViewType.value = 'stepRepeat'
  updateUrlQuery('stepRepeat')

  // Only use URLs from the API response
  if (approvalData.value?.step_and_repeat_file_url) {
    viewerUrl.value = approvalData.value.step_and_repeat_file_url
  } else if (approvalData.value?.step_repeat_viewer_url) {
    // Fallback to step_repeat_viewer_url for backward compatibility
    viewerUrl.value = approvalData.value.step_repeat_viewer_url
  } else {
    // No URL available
    viewerUrl.value = ''
    error.value = 'No step and repeat file URL available in the approval data.'
  }
}

// --------------------
// Lifecycle Hooks and Watchers
// --------------------
// Watch for visibility changes
watch(visibility, isVisible => {
  if (isVisible) {
    // eslint-disable-next-line no-console
    console.log('Tab became visible, fetching updates immediately.')
    fetchApprovalUpdates() // Fetch immediately when tab becomes visible
  } else {
    // eslint-disable-next-line no-console
    console.log('Tab became hidden, polling paused.')
  }
})

// Start polling for updates
onMounted(async () => {
  // Set page loading state
  pageLoading.value = true

  try {
    // Load user store to check authentication status
    userStore.value = await storeImports.user()

    // Load CSS files if needed (only for non-authenticated users)
    await loadCssFiles()

    const tokenValue = route.params.token
    if (!tokenValue) {
      error.value = 'No approval token provided in the URL.'
      isLoading.value = false
      pageLoading.value = false // End loading on error
      return
    }
    token.value = tokenValue
    signature.value = tokenValue // Set signature for backward compatibility

    // Read initial view type from URL query, default to 'single'
    const initialType = route.query.view === 'stepRepeat' ? 'stepRepeat' : 'single'
    currentViewType.value = initialType

    // Fetch initial approval data
    // (note: pageLoading will be set to false inside fetchApprovalData)
    fetchApprovalData(token.value)

    // Ensure URL reflects the initial state if not already set
    if (!route.query.view || route.query.view !== currentViewType.value) {
      updateUrlQuery(currentViewType.value)
    }

    // Set up the polling interval but don't fetch immediately (we already fetched above)
    pollingInterval = setInterval(() => {
      if (visibility.value && !isSubmitted.value) {
        // Only poll if tab is visible and not yet submitted
        fetchApprovalUpdates()
      }
    }, 30000) // Poll every 30 seconds
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error during initialization:', error)
    pageLoading.value = false // Make sure loading ends even on error
  }
})

// Clear interval on unmount
onUnmounted(() => {
  if (pollingInterval) {
    clearInterval(pollingInterval)

    // eslint-disable-next-line no-console
    console.log('Polling interval cleared.')
  }
})

const loadCssFiles = async () => {
  if (cssImportsLoaded.value) return

  try {
    // Only import CSS files when user is not authenticated
    if (!userStore.value?.isAuthenticated) {
      // Import CSS files
      await Promise.all([
        import('@/vendor/fomantic/dist/components/button.min.css'),
        import('@/vendor/fomantic/dist/components/accordion.min.css'),
        import('@/vendor/fomantic/dist/components/transition.min.css'),
        import('@/vendor/fomantic/dist/components/loader.min.css'),
        import('@/vendor/fomantic/dist/components/form.min.css'),
        import('@/vendor/fomantic/dist/components/message.min.css'),
        import('@/vendor/fomantic/dist/components/segment.min.css'),
        import('@/vendor/fomantic/dist/components/dimmer.min.css'),
        import('@/vendor/fomantic/dist/components/list.min.css'),
        import('@/vendor/fomantic/dist/components/header.min.css'),
        import('@/vendor/fomantic/dist/components/placeholder.min.css'),
        import('@/vendor/fomantic/dist/components/table.min.css')
      ])

      // eslint-disable-next-line no-console
      console.log('Fomantic UI CSS files loaded for non-authenticated user')
    } else {
      // eslint-disable-next-line no-console
      console.log('CSS files not loaded - user is authenticated')
    }

    cssImportsLoaded.value = true
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Failed to check authentication status:', err)
  }
}
</script>

<style lang="scss" scoped>
.approval-page {
  display: grid;
  grid-template-columns: 350px 1fr;
  height: calc(
    100vh - var(--header-height, 0px)
  ); // Use CSS variable for header height, fallback to 0px
  margin: 0;
  overflow: hidden;

  // Page loader container styles
  .page-loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

    .ui.active.dimmer {
      background-color: rgba(255, 255, 255, 0.95);
    }

    .ui.large.text.loader {
      font-size: 1.2em;
    }
  }

  // When there's an error or during loading, we need to reset the grid layout
  &:has(.error-container),
  &:has(.loader-container) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loader-container,
  .error-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .ui.message {
      max-width: 500px;
      margin: 0;
    }

    .ui.active.dimmer {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }

  .sidebar-container {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    border-right: 1px solid #ddd;
    position: relative;
    width: 350px; // Fixed width for the sidebar
    min-width: 350px; // Ensure minimum width is maintained
    max-width: 350px; // Ensure maximum width is maintained

    .ui.active.dimmer {
      z-index: 10;
    }

    :deep(.sidebar) {
      height: 100%;
      margin: 0;
      border-radius: 0;
    }
  }

  .viewer-container {
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e0e0e0;

    :deep(iframe),
    :deep(.viewer-component-root) {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
