import { createApp, h, Suspense } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedState from 'pinia-plugin-persistedstate';
import App from './App.vue';
import { bootAuthenticatedApplication } from './authenticated';
import { appSettings, backendSettings } from '@/config.js';
import { initializeStores } from '@/stores/storeBootstrap';
import {
  createAppRouter,
  createUnauthenticatedRoutes,
} from '@/router/router.js';
import scrollPosition from '@/directives/scrollPosition';
import DesignTokens from '@/plugins/designTokens';
import { loadFonts } from '@/utils/fontLoader';

// Import design tokens CSS
import '@/assets/design-tokens.css';

// --- Constants for Storage Keys ---
// Using constants prevents typos and improves maintainability
const AUTH_JUST_AUTHENTICATED_KEY = 'just_authenticated';
const AUTH_REDIRECT_PATH_KEY = 'auth_redirect_path';

// --- Pinia Setup ---
const pinia = createPinia();
pinia.use(piniaPluginPersistedState);

// Define app at module scope so it can be exported
let app;

// --- Application Initialization Function ---
async function initializeApp() {
  // Load fonts first, but don't let font loading failures block app initialization
  try {
    await loadFonts();
    if (import.meta.env.DEV) {
      // eslint-disable-next-line no-console
      console.log('Fonts loaded successfully');
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('Font loading failed, continuing with application boot:', error);
  }

  const instance = createApp({
    setup() {
      // Using Suspense for async components during routing or setup
      return () =>
        h(Suspense, null, {
          default: () => h(App),
          // Consider replacing with a dedicated loading component for better UX
          // fallback: () => h(LoadingIndicator),
          fallback: () => h('div', 'Loading...'),
        });
    },
  });

  // Register plugins
  instance.use(pinia);
  instance.use(DesignTokens);

  // Register directives conditionally (only in development)
  if (import.meta.env.DEV) {
    instance.directive('scroll-position', scrollPosition);
  }

  return instance;
}

// --- Main Application Bootstrapping Logic ---
async function bootApplication() {
  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console
    console.log('Booting application...');
  }

  // Assign to module-scoped app variable
  app = await initializeApp();

  let hasPersistedRedirect = false;

  // Check if we just completed an authentication flow
  if (sessionStorage.getItem(AUTH_JUST_AUTHENTICATED_KEY) === 'true') {
    sessionStorage.removeItem(AUTH_JUST_AUTHENTICATED_KEY);

    // Check if there's a redirect path stored from before authentication
    if (localStorage.getItem(AUTH_REDIRECT_PATH_KEY)) {
       hasPersistedRedirect = true;

       if (import.meta.env.DEV) {
         // eslint-disable-next-line no-console
         console.log('[Main] 🔍 Found redirect path in localStorage. Authenticated boot process will handle it.');
       }

       // No need for window.__hasRedirectPath flag.
       // The authenticated boot logic (bootAuthenticatedApplication)
       // should check localStorage AFTER routes are ready.
    }
  }

  // Initialize critical stores
  // IMPORTANT: Ensure initializeStores correctly waits for or triggers
  // synchronous hydration from pinia-plugin-persistedstate if needed.
  const { userStore } = await initializeStores();

  const isAuthenticated = userStore.isAuthenticated;
  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console
    console.log('[Main] User isAuthenticated:', isAuthenticated);
  }

  // --- Conditional Bootstrapping ---
  if (isAuthenticated) {
    if (import.meta.env.DEV) {
      // eslint-disable-next-line no-console
      console.log('[Main] Booting authenticated application...');
    }

    await bootAuthenticatedApplication(app, { hasPersistedRedirect });
  } else {
    if (import.meta.env.DEV) {
      // eslint-disable-next-line no-console
      console.log('[Main] Unauthenticated user detected, setting up guest routes.');
    }
    // Setup router with unauthenticated routes
    const routes = createUnauthenticatedRoutes();
    const router = createAppRouter(routes);

    app.use(router);
  }

  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console
    console.log('[Main] Mounting app to #app');
  }
  app.mount('#app');

  // Make appSettings globally available (consider alternatives like provide/inject or direct imports)
  // This is generally acceptable but be mindful of polluting the global Vue instance properties.
  app.config.globalProperties.appSettings = appSettings;

  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console
    console.log('[Main] Application boot complete.');
  }

  return app;
}

// --- Start the Application ---
// No need for DOMContentLoaded listener if script is module/deferred or at end of body.
// Vue's mount() waits for the element.
bootApplication().catch(error => {
  // eslint-disable-next-line no-console
  console.error('💥 Application boot failed:', error);
  // Optional: Display a user-friendly error message in the DOM
  try {
     const appElement = document.getElementById('app');
     if (appElement) {
        appElement.innerHTML = '<div style="padding: 20px; text-align: center; color: red;">Failed to load application. Please try refreshing the page or contact support.</div>';
     }
  } catch (displayError) {
     // eslint-disable-next-line no-console
     console.error('Failed to display boot error message:', displayError);
  }
});


// --- HMR Handling (Vite) ---
// if (import.meta.hot) {
//   import.meta.hot.accept(async (newModule) => {
//     if (import.meta.env.DEV) {
//       // eslint-disable-next-line no-console
//       console.log('[VITE] HMR update detected for main.js...');
//     }
//     // Unmount the old app instance cleanly
//     if (app) {
//       try {
//         app.unmount();
//         if (import.meta.env.DEV) {
//           // eslint-disable-next-line no-console
//           console.log('[VITE] Previous app instance unmounted.');
//         }
//       } catch (e) {
//         // eslint-disable-next-line no-console
//         console.error('[VITE] Error unmounting previous app instance:', e);
//       }
//     }
//     // It's generally better to re-run the boot process fully
//     // to ensure all state and dependencies are correctly re-initialized.
//     // Check if the new module itself has the boot function if needed,
//     // but typically just re-calling the function defined in this scope is fine.
//     if (newModule && newModule.bootApplication) {
//         await newModule.bootApplication(); // If bootApplication was exported differently
//     } else {
//         await bootApplication(); // Re-run the boot process defined in this file
//     }
//     if (import.meta.env.DEV) {
//         // eslint-disable-next-line no-console
//         console.log('[VITE] Application rebooted after HMR.');
//     }
//   });
// }


// Export necessary variables/instances
export { app, appSettings, backendSettings };